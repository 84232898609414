/** import npm */
import Lottie from "lottie-react";

/** import local files */
import loaderJson from "../assests/json/loader.json";


/** code start */

const Loader = () => {
    return (
        <div className="custom_loader d-flex flex-column ">
            <Lottie
                animationData={loaderJson}
                className="success_json"
                loop={true}
            />
            <p className="fw-bold">Please Connect your wallet...</p>
        </div>
    )
}

export default Loader