import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Countdown, { zeroPad } from "react-countdown";

import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";

import bscIcon from "../../assests/images/bscicon.png";
import clipPath from "../../assests/images/clippath.png";
import clock from "../../assests/images/clock.png";
import history from "../../assests/images/history.png";
import PredictionCard from "../../components/PredictionCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, FreeMode } from "swiper/modules";

import SwiperCore from "swiper";
import PredictionModal from "../../modals/PredictionModal";
import { Offcanvas } from "react-bootstrap";
import HistoryCanva from "../../OffCanva/HistoryCanva";
import {
  UseFetchPnl,
  UseFetchPredictionRecords,
  UseFetchUserRounds,
} from "../../hooks/usePrediction";

// Install Swiper modules
SwiperCore.use([Navigation]);

const Prediction = () => {
  const swiperRef = useRef(null);
  const [interval, setinterval] = useState([]);
  const [userRecord, setUserRecord] = useState({});
  const [ActiveRecord, setActiveRecord] = useState({});
  const [UnActiveRecord, setUnActiveRecord] = useState({});
  const [pnlRecord, setPnlRecord] = useState({});
  const [wonRecord, setWonRecord] = useState({});
  const [lossRecord, setLossRecord] = useState({});
  const [common, setCommon] = useState({});
  const [InitialAmt, setInitialAmt] = useState({});

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    // Clear any existing interval
    let oldIntervalId = localStorage.getItem("Interval");
    if (oldIntervalId) {
      clearInterval(Number(oldIntervalId));
      localStorage.removeItem("Interval");
    }

    // Set a new interval
    const intervalId = setInterval(() => {
      FetchPrediction();
    }, 1000);

    localStorage.setItem("Interval", intervalId?.toString());

    // Fetch history and PnL once when the component mounts
    FetchHistory();
    FetchPnl();
  }, []);


  const FetchPrediction = async () => {
    const FetchData = await UseFetchPredictionRecords();
    console.log("FetchDataFetchData", FetchData);
    setInitialAmt(FetchData?.FetchliveBalance);
    let laterData = FetchData?.records?.find((val) => val?.cardType == "Later");
    setPredictionCards(FetchData?.records);
    setinterval(laterData?.latetCount);
  };

  async function FetchHistory() {
    const FetchData = await UseFetchUserRounds();
    setUserRecord(FetchData);

    let CollectedMapData = FetchData?.filter((val) => {
      return val?.claimed != false;
    });
    setActiveRecord(CollectedMapData);

    let UnCollectedMapData = FetchData?.filter((val) => {
      return val?.claimed == false;
    });
    setUnActiveRecord(UnCollectedMapData);
  }

  async function FetchPnl() {
    const FetchData = await UseFetchPnl();
    let commondata = FetchData;
    setCommon(commondata);
    let enderedData = FetchData?.records;
    setPnlRecord(enderedData);
    let WonData = FetchData?.wonData;
    setWonRecord(WonData);
    let lossData = FetchData?.lossData;
    setLossRecord(lossData);
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [predictionCards, setPredictionCards] = useState([
    // {
    //   id: 1,
    //   cardType: "LiveUP",
    //   title: "Live",
    //   icon: expireIcon,
    //   cardCount: "#254034",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 2,
    //   cardType: "LiveDown",
    //   title: "Live",
    //   icon: expireIcon,
    //   cardCount: "#254034",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 3,
    //   cardType: "Next",
    //   title: "Next",
    //   icon: expireIcon,
    //   cardCount: "#254033",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 4,
    //   cardType: "Later",
    //   title: "Later",
    //   icon: expireIcon,
    //   cardCount: "#254033",
    //   payout: "1.96",
    //   startTime: 600000,
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 5,
    //   cardType: "Later",
    //   title: "Later",
    //   icon: expireIcon,
    //   cardCount: "#254033",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   startTime: 60000,
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 6,
    //   cardType: "UpExpired",
    //   title: "Expired",
    //   icon: expireIcon,
    //   cardCount: "#254033",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
    // {
    //   id: 7,
    //   cardType: "DownExpired",
    //   title: "Expired",
    //   icon: expireIcon,
    //   cardCount: "#254033",
    //   payout: "1.96",
    //   priceType: "Closed Price",
    //   priceValue: "406.7348",
    //   increaseValue: "0.1500",
    //   lockedPrice: "406.1500",
    //   pricePool: "7.5566",
    //   downPayout: "2.04",
    // },
  ]);
  const [predictionModalShow, setPredictionModalShow] = useState(true);
  const [isConnectWallet, setIsConnectWallet] = useState(true);

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {zeroPad(minutes)} m : {zeroPad(seconds)} s
    </span>
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {predictionModalShow && (
        <PredictionModal
          show={predictionModalShow}
          onHide={() => {
            setPredictionModalShow(false);
          }}
        />
      )}
      <Header />

      <div className={`${cascading.innerpages} ${cascading.predictionPage} `}>
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Prediction</h3>
            <div className={`row py-5`}>
              <div
                className={`col-sm-6 col-md-4 d-flex justify-content-center justify-content-md-start`}
              >
                <button
                  className={`d-flex align-items-center justify-content-between ${cascading.valueButton}`}
                >
                  <div className={`d-flex align-items-center gap-2`}>
                    <div
                      className={`d-flex align-items-center justify-content-center ${cascading.logoBg}`}
                    >
                      <img src={bscIcon} alt="icons" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                  <p className={`mb-0 ${cascading.amount}`}>${InitialAmt?.livedata ? InitialAmt?.livedata : 0}</p>
                </button>
              </div>
              <div
                className={`col-sm-4 col-md-4 d-none d-md-flex justify-content-center align-items-center`}
              >
                <button
                  className="swiper-button-prev1 border-0 outline-0 bg-transparent"
                  onClick={() => goPrev()}
                >
                  <FaArrowLeft fill="#7645d9" fontSize={18} className="me-2" />
                </button>

                <div>
                  <img src={clipPath} alt="clip path" className={`img-fluid`} />
                </div>
                <button
                  className="swiper-button-next1 border-0 outline-0 bg-transparent"
                  onClick={() => goNext()}
                >

                  <FaArrowRight fill="#7645d9" fontSize={18} className="ms-2" />
                </button>
              </div>
              <div
                className={`${cascading.right} col-sm-6 col-md-4  d-flex justify-content-center justify-content-md-end   align-items-center mt-4 mt-sm-0`}
              >
                <div className={`d-flex align-items-center gap-3`}>
                  <p className={`mb-0 ${cascading.timerValue}`}>
                    {interval && (
                      <Countdown
                        date={new Date(interval)}
                        renderer={renderer}
                      />
                    )}
                  </p>
                  <img
                    src={clock}
                    alt="clock"
                    className={`${cascading.clock}`}
                  />
                  <div
                    className={` d-flex align-items-center justify-content-center ${cascading.historyBg}`}
                    onClick={() => handleShow()}
                  >
                    <img src={history} alt="histroy" className={`img-fluid`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`prediction_swiper`}>
          {" "}
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={15}
            loop={false}
            ref={swiperRef}
            // cssMode={true}
            // navigation={{
            //   nextEl: ".next",
            //   prevEl: ".prev",
            // }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={{
              nextEl: ".swiper-button-next1",
              prevEl: ".swiper-button-prev1",
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {predictionCards?.map((val) => (
              <SwiperSlide>
                <PredictionCard
                  FetchHistory={() => { FetchHistory(); FetchPnl() }}
                  val={val}
                  isConnectWallet={isConnectWallet}
                  setIsConnectWallet={setIsConnectWallet}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Footer />

      <HistoryCanva FetchHistory={() => { FetchHistory(); FetchPnl() }} show={show} Active={ActiveRecord} UnActive={UnActiveRecord} handleClose={handleClose} ListData={userRecord} pnlRecord={pnlRecord} wonRecord={wonRecord} lossRecord={lossRecord} common={common} />
    </div>
  );
};

export default Prediction;
