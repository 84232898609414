import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";
import { FaRegCopy, FaExternalLinkAlt } from "react-icons/fa";

import { getOperatorTreasuryInjectorAddress, setOperatorTreasuryInjectorAddress } from "../../hooks/useLottery";
import{ validationAddress } from "../../hooks/useValidation";
import { getWalletAddress } from "../../lib/localStorage";
import { isEmpty } from "../../lib/isEmpty";
import { iconTheme, position, style } from "../../hooks/useToast";
import toast from "react-hot-toast";
import Web3 from "web3";
// import { operators } from "ajv/dist/compile/codegen";





// Import Swiper React components



// import required modules


// Install Swiper modules

const Lotterysettings = () => {

  const [isConnectWallet, setIsConnectWallet] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Operator,setOperator] = useState("");
  const [Injector,setInjector] = useState("");
  const [Treasury,setTreasury] = useState("");
  const [ValidateError,setValidateError] = useState("")
  const [accountInfo,setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))


  useEffect(()=>{
    getLotteryData();
  
  },[])


  const getLotteryData = async() =>{
   
    var getOperator = await getOperatorTreasuryInjectorAddress()

setOperator(getOperator?.operatorAddress)
setTreasury(getOperator?.TreasuryAddress)
setInjector(getOperator?.injectorAddress)
   
  }



  const onSubmit = async()=>{
    var web3 = new Web3(window.ethereum);


    var data ={
      Operator: Operator,
      Injector:Injector,
      Treasury:Treasury

    }
let result;
    var   errors  = await validationAddress(data)
   if(isEmpty(errors)){

    console.log("errors>>>1");

    if(Operator.length > 48  || Injector.length > 48 || Injector.Treasury > 48 || 
      !web3.utils.isAddress(Operator) || !web3.utils.isAddress(Injector) || !web3.utils.isAddress(Treasury)) {
      await toast.error("Please check your address",
        {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        setValidateError(errors);
    }else{
      result =  await setOperatorTreasuryInjectorAddress(Operator,Treasury,Injector,accountInfo);
    }
    
    } 
   else {
      console.log("errors>>>3");

      if (
    
        !web3.utils.isAddress(Operator) ||
        !web3.utils.isAddress(Injector) ||
        !web3.utils.isAddress(Treasury)
      ) {
        console.log("errors>>>2");
        await toast.error("Please check your address",
          {
              position: position.position,
              style: style,
              iconTheme: iconTheme,
          })
      }else{
        await toast.error("Please Enter Required Fields...!!!",
          {
              position: position.position,
              style: style,
              iconTheme: iconTheme,
          })
          setValidateError(errors);
      }
     
    }
     
}

const copyText = (a, b) => {
  toast.success("Address Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })
}


  return (
    <div>
     
      <Header />
      <div className={`${cascading.innerpages} ${cascading.predictionPage} ${cascading.lotteryPage}`}>
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Lottery Settings</h3>
          <div className="mt-4">

          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 `}>
              <div className={` ${cascading.cardstylelottery}`}>
               
                <div className={`${cascading.midrow}`}>
                
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Operator Address 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          type="text"
                          className={`from-control`}
                          id="operator"
                          aria-describedby="operator"
                          placeholder="Operator Address"
                          value={Operator} 
                          onChange={(e) => { setOperator(e.target.value) }}
                         
                        />
                        <CopyToClipboard text={Operator} onCopy={() => copyText('invite link', `${Operator}`)}
                
              >
                <button className={`${cascading.btncopynew}`}><FaRegCopy />
                  </button>
              </CopyToClipboard>
                      </div>
             
                    </div>

                    
                  </div>
                  {ValidateError.Operator && (
                      <span className="text-danger f-12">
                        {ValidateError.Operator}
                      </span>
                    )}

                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Treasury Address
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          type="text"
                          className={`from-control`}
                          id="treasury"
                          aria-describedby="treasury"
                          placeholder="Treasury Address"
                          value={Treasury} 
                          onChange={(e) => { setTreasury(e.target.value) }}
                         
                        />
                         <CopyToClipboard text={Treasury} onCopy={() => copyText('invite link', `${Treasury}`)}
                
                >
                  <button className={`${cascading.btncopynew}`}><FaRegCopy />
                    </button>
                </CopyToClipboard>
                      </div>
             
                    </div>
                   
                  </div>
                  {ValidateError.Treasury && (
                      <span className="text-danger">
                        {ValidateError.Treasury}
                      </span>
                    )}

                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                      Injector Address
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          type="text"
                          className={`from-control`}
                          id="injector"
                          aria-describedby="injector"
                          placeholder="Injector Address"
                          value={Injector} 
                          onChange={(e) => { setInjector(e.target.value) }}
                         
                        />

<CopyToClipboard text={Injector} onCopy={() => copyText('invite link', `${Injector}`)}
                
                >
                  <button className={`${cascading.btncopynew}`}><FaRegCopy />
                    </button>
                </CopyToClipboard>
                      </div>
             
                    </div>


                   
                  </div>
                  {ValidateError.Injector && (
                      <span className="text-danger">
                        {ValidateError.Injector}
                      </span>
                    )}
                  <button
                      className={`btn ${cascading.formbtn}`}
                   onClick={() => onSubmit()} >
                     Update
                    </button>
                </div>

              
             



              
              
              </div>

          
            </div>
          </div>
       
          </div>
          </div>
        </div>

      
      </div>

      <Footer />

    </div>
  );
};

export default Lotterysettings;
