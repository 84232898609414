import { LotteryContract } from "../config/lottery"
import { iconTheme, position, style } from "./useToast"

import { useWeb3 } from "./useWeb3"
import Lottery_Abi from "../config/abi/lotteryABI.json"
import ERC20_Abi from "../config/abi/ERC20.json"
import toast from "react-hot-toast"
import { EstGas } from './useCommon'



export const UseLotteryContract = async()=>{
    try{
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(Lottery_Abi, LotteryContract);
   
    return contract;
}catch (e) {
               
}  
}



export const UseERC20Lottery = async (Token) => {
    const web3 = await useWeb3();
    try {
        
        const contract = new web3.eth.Contract(ERC20_Abi,Token);
        console.log("UseERC20LotteryToken",contract);
        return contract;
    } catch (err) {
        console.log(err, "UseERC20Lottery__err")
    }
}


export const getCakeAddress = async()=>{
    try{
        const LotteryContract = await UseLotteryContract();
        const Address = await LotteryContract.methods.cakeToken().call();
        return Address;
    }catch (e) {
                   
    }
}

export const getTokenBalance =  async(account) =>{
    try {
        const tokenaddress = await getCakeAddress()    
        const tokenContarct = await UseERC20Lottery(tokenaddress);
        const balance = await tokenContarct.methods.balanceOf(account).call()
        return balance;
        
    } catch (error) {
        
        console.log("getTokenBalanceError",error);
    }
}

export const getOwnerAddress = async()=>{
    try{
        const LotteryContract = await UseLotteryContract();
        const Address = await LotteryContract.methods.owner().call();
        return Address;
    }catch (e) {
                   
    }
}

export const getOperatorTreasuryInjectorAddress = async()=>{

   
    try{
        const LotteryContract = await UseLotteryContract();
        const injectorAddress = await LotteryContract.methods.injectorAddress().call();
        const operatorAddress = await LotteryContract.methods.operatorAddress().call();
        const TreasuryAddress = await LotteryContract.methods.treasuryAddress().call();
        
      
        const Address = {
            injectorAddress: injectorAddress,
            operatorAddress: operatorAddress,
            TreasuryAddress: TreasuryAddress,
        }
        return Address;
    }catch (e) {
        console.log("getOperatorTreasuryInjectorAddressError",e);
                   
    }
}
    
export const setOperatorTreasuryInjectorAddress = async(operator, treasury, injector,account)=>{

   
    try{
       
        const LotteryContract = await UseLotteryContract();
        var result =  LotteryContract.methods.setOperatorAndTreasuryAndInjectorAddresses(operator, treasury, injector).send({ from: account })
        await toast.promise(result, {
            loading: "Addresses are Updating...!!",
            success: 'Updated Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
               window.location.href = "/lottery"
                return true;
       
    }catch (e) {
        console.log("setOperatorTreasuryInjectorAddress",e);
         return false;          
    }
}
  

export const ApproveToken = async (account, token) => {
    const web3 = await useWeb3()
    try {
        let contract = await UseERC20Lottery(token);
        let amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
        let contractAddress = LotteryContract;
        let params = [web3.utils.toChecksumAddress(contractAddress), amount]
        // const { gasLimit, gasPrice } = await EstGas(params, ERC20_Abi, token, 'approve', account)
        let symbol = await contract.methods.symbol().call();
      
        let Approve =  contract.methods.approve(web3.utils.toChecksumAddress(contractAddress), amount).send({ from: web3.utils.toChecksumAddress(account) })
        await toast.promise(Approve, {
            loading: `Approving your ${symbol}`,
            success: 'Approved Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            
    }catch(err) {
        console.log("ApproveToken__err", err)
        return false
    }
}


export const checkIsApproved = async (account,token) => {
    try{
        console.log("account,token", account,token);
        var tokenContarct = await UseERC20Lottery(token);
        let  contractAddress = LotteryContract;
        const allow = await tokenContarct.methods.allowance(account,contractAddress).call();
        return (parseInt(allow)>0)?true:false;
    }catch (e) {
        console.log("checkIsApprovedError",e);
        
    }
}



export const GetallowanceAmount = async (account,token) => {
    
    console.log("token>>>>>",account,token);
    try{
        const erc20 = await UseERC20Lottery(token);
        let  contractAddress = LotteryContract;
        console.log("erc20_data", erc20)
        const allow = await erc20.methods.allowance(account,contractAddress).call();
        return allow;
}catch (e) {
    console.log("GetallowanceAmountError",e);
               
}
}

export const Usecheckweb3Address = async(operator,treasury,injector) =>{
const web3 = await useWeb3();
if (
    !web3.utils.isAddress(operator) ||
    !web3.utils.isAddress(treasury) ||
    !web3.utils.isAddress(injector)
  ) {
    await toast.error("Check your address",
            {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })
   
    return false;
  }
  else{
    return true;
  }
}


export const StartLottery = async(endtime, ticketPice, discount,rewards,fee,account)=> {

   
    try{
       
        const LotteryContract = await UseLotteryContract();
        var result = LotteryContract.methods.startLottery(endtime.toString(), ticketPice.toString(), discount,rewards,fee).send({ from: account })
       
        await toast.promise(result, {
            loading: "Lottery Starting...!!",
            success: 'Lottery Started Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                window.location.href = "/addlottery"
            return true;


       
    }catch (e) {
        console.log("startLotteryError",e);
         return false;          
    }
}
  


export const BuyTickets = async(ticketArray,account)=>{

    console.log("currentLotteryId",ticketArray);
   
    try{
       
        const LotteryContract = await UseLotteryContract();
        const currentLotteryId = await LotteryContract.methods.currentLotteryId().call();
        var result = LotteryContract.methods.buyTickets(currentLotteryId,ticketArray).send({ from: account })
       
        await toast.promise(result, {
            loading: "Buying you Ticket...!!",
            success: 'Buy your tickets Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                window.location.reload()
                return true;

       
    }catch (e) {
        console.log("startLotteryError",e);
         return false;          
    }
}

export const getLotteryInfo = async(account)=>{


   
    try{
        const LotteryContract = await UseLotteryContract();
        const currentLotteryId = await LotteryContract.methods.currentLotteryId().call();
        const UserInfoForLotteryId = await LotteryContract.methods.viewUserInfoForLotteryId(account,currentLotteryId, 0, 100000).call();
        const getUserLottery = await LotteryContract.methods.getUserLottery(account).call();
        const viewcurrentLottery = await LotteryContract.methods.viewLottery(currentLotteryId).call();
        const UniqueLott = {};
        getUserLottery.forEach(function (x) {
          if (x != currentLotteryId) UniqueLott[x] = (UniqueLott[x] || 0) + 1;
        });

        var histArray = [];

        for (const property in UniqueLott) {
          var obj = {};
          var ticketid = property;
          var count = UniqueLott[property];
          var hisTick = await LotteryContract.methods.viewLottery(ticketid).call();

          var enddate = new Date(hisTick.endTime * 1000);

          obj = { ticketid: ticketid, count: count, enddate: enddate };

          histArray.push(obj);
        }
        
      
        const data = {
            currentLotteryId: currentLotteryId,
            UserInfoForLotteryId: UserInfoForLotteryId,
            getUserLottery: getUserLottery,
            histArray:histArray,
            viewcurrentLottery: viewcurrentLottery
        }
        return data;
    }catch (e) {
        console.log("getLotteryInfo",e);
                   
    }
}


export const UseviewUserInfoForLotteryId = async(account,lotteryId) => {
   
    try {
        const LotteryContract = await UseLotteryContract();    
        const UserInfoForLotteryId = await LotteryContract.methods.viewUserInfoForLotteryId(account,lotteryId,0,100000).call();
        return UserInfoForLotteryId;
    } catch (error) {
       console.log("UseviewUserInfoForLotteryIdError",error); 
    }
}

export const ViewCurrentLottery = async(lotteryId) => {
   
    try {
        const LotteryContract = await UseLotteryContract();    
        const viewLottery = await LotteryContract.methods.viewLottery(lotteryId).call();
        return viewLottery;
    } catch (error) {
       console.log("viewLotteryError",error); 
    }
}



export const ViewAllLottery = async() => {
   
    try {
        const LotteryContract = await UseLotteryContract();    
        const allLottery = await LotteryContract.methods.viewTotalLottery().call();
        return allLottery;
    } catch (error) {
       console.log("viewLotteryError",error); 
    }
}




export const CloseLottery = async(lotteryid,account) => {
   
 try {
        const LotteryContract = await UseLotteryContract();    
        const close =  LotteryContract.methods.closeLottery(lotteryid).send({ from: account })
        await toast.promise(close, {
            loading: "Closing you Lottery...!!",
            success: 'Close your Lottery Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                 window.location.href = "/lotterylist"
        return close;
    } catch (error) {
       console.log("closeError",error); 
    }
}


export const DrawnfinalNumberandReward = async(lotteryid,account) => {
    try {
           const LotteryContract = await UseLotteryContract();    
           const close =  LotteryContract.methods.drawFinalNumberAndMakeLotteryClaimable(lotteryid,false).send({ from: account })
           await toast.promise(close, {
               loading: "Distributing Rewards...!!",
               success: 'Distributed Rewards Successfully',
               error: 'Try Again',
               }, 
                   {
                       position: position.position,
                       style: style,
                       iconTheme: iconTheme,
                   })
                   window.location.href = "/lotterylist"
                              return close;
       } catch (error) {
          console.log("closeError",error); 
       }
   }



   export const ViewNumberandStatus = async(bracArrayTic) => {
   
    try {
        const LotteryContract = await UseLotteryContract();    
        const data = await LotteryContract.methods.viewNumbersAndStatusesForTicketIds(bracArrayTic).call();
        return data;
    } catch (error) {
       console.log("viewLotteryError",error); 
    }
}

export const Claimrewards = async(ticId,bracArrayTic,bracArray,account) => {
    try {
           const LotteryContract = await UseLotteryContract();    
           const close = LotteryContract.methods.claimTickets(ticId,bracArrayTic,bracArray).send({ from: account })
           await toast.promise(close, {
               loading: "Claim Rewards...!!",
               success: 'Claim Rewards Successfully',
               error: 'Try Again',
               }, 
                   {
                       position: position.position,
                       style: style,
                       iconTheme: iconTheme,
                   })
           return close;
       } catch (error) {
          console.log("closeError",error); 
       }
   }
