import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/unstakestyle.module.css";
// import toast, { Toaster } from 'react-hot-toast';

import { useDispatch } from "react-redux";

import { IoMdClose } from "react-icons/io";
import { AiOutlineCalculator } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import bnbimg from "../assests/images/bnb.png";
import BigNumber from "bignumber.js";

//lib
import { toFixedNumber } from "../lib/FixedNumber";

//hooks
import { UnStake } from "../hooks/usePools";
import {
  getActivePools,
  fetchPoolsStakingLimits,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
} from "../hooks/usePools";

export default function Unstakepoolmodal(props) {
  // state
  const [unstakepoolmodal, setUnstakepoolmodal] = useState(true);
  const [data, setData] = useState();
  const [bal, setBal] = useState();
  const [unstakeValue, setUnstakeValue] = useState("");
  const [points, setPoints] = useState(0);
  const [unStakingBal, setUnStakingBal] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const { userData, datas } = props.unstake;



  const rangeOnchange = async (value) => {
    // const { name, value } = e.target;
    setPoints(value);
    let stakingbalance = (userData?.stakedBalance * value) / 100;
    const usdValueStaked = new BigNumber(stakingbalance).times(
      datas.stakingTokenPrice
    );
    setUnStakingBal(stakingbalance / 10 ** 18);
    setUsdValue(usdValueStaked.toNumber() / 10 ** 18);
  };

  const onUnStakevalueChange = (e) => {
    try {
      const { value, name } = e.target;
      setUnStakingBal(value);
      const usdValueStaked = new BigNumber(value * 10 ** 18).times(
        datas.stakingTokenPrice
      );
      console.log("usdValueStaked", usdValueStaked);
      setUsdValue(usdValueStaked.toNumber() / 10 ** 18);
    } catch (err) {
      console.log(err, "onStakevalueChange");
    }
  };

  const unStaking = async () => {
    setPending(true);
    console.log("unStakingBal", unStakingBal, unStakingBal * 10 ** 18);
    let unstake = await UnStake(
      toFixedNumber(parseFloat(unStakingBal * 10 ** 18).toFixed(0)),
      datas?.contractAddress
    );
    console.log("unstake", unstake);
    if (unstake == true) {
      fetchPoolsPublicDataAsync(dispatch);
      fetchPoolsUserDataAsync(dispatch);
      toast.success(
        `Your ${datas?.stakingToken?.symbol} earnings have also been unstaked to your wallet!`
      );
      props.onHide();
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={unstakepoolmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle}`}>Unstake Pool</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            {/* <p className='text-center text-white'>Max stake for this pool: 3 testDB</p> */}

            <div className={`${cascading.inputsec} mb-4`}>
              <div
                className={`${cascading.balancerow} ${cascading.balancerownowrap}`}
              >
                <p className={`${cascading.inputlabel} mb-0`}>Unstake</p>
                <div className="d-flex align-items-center">
                  <img
                    src={props?.image}
                    className={`img-fluid ${cascading.logoimg_coin}`}
                    alt="img"
                  />
                  <p className={`${cascading.inputlabel} mb-0 ms-2`}>
                    {datas?.stakingToken?.symbol}
                  </p>
                </div>
              </div>

              <div className={`${cascading.inputsecrowcol}`}>
                <div>
                  <input
                    type="text"
                    className={`from-control`}
                    id="tokenAval"
                    aria-describedby="emailHelp"
                    placeholder="0.0"
                    value={toFixedNumber(unStakingBal)}
                    onChange={(e) => {
                      onUnStakevalueChange(e);
                    }}
                  />
                </div>

                <div className={`${cascading.rightmaxbtn}`}>
                  {/* <button
                                      className={`btn ${cascading.currencybtn}`}
                                     > Max </button> */}

                  <p className={`${cascading.inputlabel} mb-0`}>
                    ~{" "}
                    {usdValue
                      ? toFixedNumber(parseFloat(usdValue).toFixed(25))
                      : 0.0}{" "}
                    USD
                  </p>
                </div>
              </div>
            </div>
            <p className={`${cascading.inputlabelwhite} text-end`}>
              Balance :{toFixedNumber(userData?.stakedBalance / 10 ** 18)}
            </p>
            {/* <div className={`${cascading.inputsec} mb-4`}>
                            <div className={`${cascading.balancerow}`}>
                                <p className={`${cascading.inputlabel}`}>Unstake</p>
                                <p className={`${cascading.inputlabel}`}>Balance :0.305678456789001</p>
                            </div>

                            <div className={`${cascading.inputsecrow}`}>
                                <div >
                                    <input
                                        type="text"
                                        className={`from-control`}
                                        id="tokenAval"
                                        aria-describedby="emailHelp"
                                        placeholder="0.0"
                                    />
                                </div>

                                <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                
                                    <p className={`${cascading.inputlabel} ms-2 mb-0`}>DSP-BNB LP</p>


                                </div>



                            </div>

                        </div> */}

            <div className={`${cascading.modalslider}`}>
              <div className={`${cascading.rangesec} mt-3`}>
                <p className={`${cascading.inputlabeltheem}`}>{points} %</p>

                <input
                  type="range"
                  className={`form-range ${cascading.rangeinput}`}
                  id="customRange"
                  name="points"
                  min="0"
                  max="100"
                  value={points}
                  onChange={(e) => {
                    rangeOnchange(e.target.value);
                  }}
                />
                <div
                  className={`${cascading.btngrpsec} ${cascading.btngrpsecnowrap}`}
                >
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(25);
                    }}
                  >
                    25%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(50);
                    }}
                  >
                    50%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(75);
                    }}
                  >
                    75%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(100);
                    }}
                  >
                    Max
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4">
              <button
                className={`btn ${cascading.cancelbtn}`}
                type="button"
                onClick={props.onHide}
              >
                Cancel
              </button>

              <button
                className={`btn ms-3 ${cascading.connectwalletbtn}`}
                type="button"
                onClick={() => {
                  unStaking();
                }}
                disabled={unStakingBal == 0}
              >
                {pending == true ? "Confirming..." : "Confirm"}
              </button>
            </div>
            {/* <div className='text-center mt-4'>
                            <button className={`${cascading.declabel}`} target="_blank" >Get DSP-BNB LP</button>

                        </div> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
