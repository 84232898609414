import { isEmpty } from "../src/lib/isEmpty";
import { useEffect } from "react";

import { getFarmsInfo } from './hooks/useFarms';

import { useSelector, useDispatch } from "react-redux";
import {
  WALLET_STATUS,
  WALLET_ADDRESS,
  NATIVE_BALANCE,
  XTOKEN_BALANCE,
  SLIPPAGE,
  MULTI_HOPS,
  DEADLINE,
  EXPERT_MODE,
  FARMS_DATA
} from "../src/constants";
import {
  getDeadLine,
  getExpertMode,
  getMultiHops,
  getSlippage,
  getWalletAddress,
  setDeadLine_local,
  setExpertMode,
  setMultiHops,
  setSlippage,
} from "../src/lib/localStorage";
import { consolelog } from "./lib/consolelog";

//contract
import { BalanceNative } from "./hooks/useNativeToken";
import { XBalance } from "./hooks/useXtoken";

const HelperRoute = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    if (!getSlippage()) {
      setSlippage(0.5);
      dispatch({
        type: SLIPPAGE,
        payload: 0.5,
      });
    } else if (getSlippage()) {
      dispatch({
        type: SLIPPAGE,
        payload: getSlippage(),
      });
    }

    if (!getMultiHops()) {
      setMultiHops(1);
      dispatch({
        type: MULTI_HOPS,
        payload: 1,
      });
    } else if (getMultiHops()) {
      dispatch({
        type: MULTI_HOPS,
        payload: getMultiHops(),
      });
    }

    if (!getDeadLine()) {
      setDeadLine_local(10);
      dispatch({
        type: DEADLINE,
        payload: 1,
      });
    } else if (getDeadLine()) {
      dispatch({
        type: DEADLINE,
        payload: getDeadLine(),
      });
    }

    if (getExpertMode() == undefined) {
      setExpertMode(false);
      dispatch({
        type: EXPERT_MODE,
        payload: false,
      });
    } else {
      dispatch({
        type: EXPERT_MODE,
        payload: getExpertMode(),
      });
    }

    if (getWalletAddress()) {
      dispatch({
        type: WALLET_ADDRESS,
        payload: getWalletAddress(),
      });
      getNativebaln();
      getXtoken();
    }
    if (!getWalletAddress()) {
      sessionStorage.setItem("walletStatus", "disconnect");
    }
    dispatch({
      type: WALLET_STATUS,
      payload:
        getWalletAddress() === undefined
          ? "disconnect"
          : sessionStorage.getItem("walletStatus"),
    });
  }, []);


  useEffect(() => {
    if (localStorage.getItem('FarmInterval')) {
        let oldInterval = localStorage.getItem('FarmInterval')
        clearInterval(oldInterval);
    }
    const interval = setInterval(async () => {
        GetFarmInfo()
    }, 3000)
    localStorage.setItem('FarmInterval', interval)
}, [])

const GetFarmInfo = async () => {
  try {
      console.log("GetFarmInfo_")
      let Farms = await getFarmsInfo()
      console.log(Farms, "GetFarmInfo_")
      dispatch({
          type: FARMS_DATA,
          payload: Farms
      })
  } catch (err) {
      console.log(err, "GetFarmInfo__Err")
  }
}




  const getNativebaln = async () => {
    try {
      let balance = await BalanceNative(getWalletAddress());
      balance = parseFloat(balance) / 10 ** 18;
      dispatch({
        type: NATIVE_BALANCE,
        payload: balance,
      });
    } catch (err) {
      consolelog("getNativebaln__err", err, true);
    }
  };
  const getXtoken = async () => {
    try {
      let balance = await XBalance(getWalletAddress());
      balance = parseFloat(balance) / 10 ** 18;
      dispatch({
        type: XTOKEN_BALANCE,
        payload: balance,
      });
    } catch (err) {
      consolelog("getNativebaln__err", err, true);
    }
  };
};

export default HelperRoute;
