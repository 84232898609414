import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/completedsupplystyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";
import coinimg from "../assests/images/bnb.png";

import metaicon from "../assests/images/metamask.png";
import walleticon from "../assests/images/walletconnect.png";

import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { CHAINS } from '../config/env'
import { GetChainIndex } from '../hooks/useWeb3'
export default function CompletedWrapmodal(props) {
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>

          <FaArrowUp fill='#fff' fontSize={40} />

          <p className={`${cascading.vallabel}`}>Transaction Submitted</p>
          {/* <Link to={`${CHAINS.TRANSACTION}/${localStorage.getItem("addLiquidity")}`} className={`${cascading.declabel}`}>View on BscScan</Link> */}
          <button className={`${cascading.declabel}`} onClick={() => { window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${props.txn.transactionHash}`) }} >View on BscScan</button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
