import bsc from "../assests/images/bsc.png"
import avax from "../assests/images/Avalanche.png"
import arbitrum from "../assests/images/arb.png"
import eth from "../assests/images/eth.png"
import zkSync from "../assests/images/zkSync.png"
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;

export const MAX_UNIT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const ALLOWED_PRICE_IMPACT_LOW= 1 //1%
// Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM= 3 //3%
// Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = 5  //5%
// Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN= 10 //10%
// Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT  = 15 //15%
// Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%
  
export const RPC_URL = "https://sepolia.infura.io/v3/";
 export const CHAIN_ID = 11155111;
 
 export const ONEDAYINSECONDS = 86400;
 export const frontendurl  =     window.location.origin;

 export const AdminSaleAddress = "0x518221c5013D745359A2B86863Ef21C75075C623" 

 export const AdminTokenAddress ="0x16ff9EDC133af5fc4ff46bF15562A2F200952A42"   

 export const userFee = 5

 export const userFeeWithtoken = 4

 export const MAX_HOPS =  3


 export const moonpayAPIkey = "pk_test_dp8X8WruBRU1SPvuYOE6PuTdGQ6WS3";  //working api

//  pk_live_jsrCo8r6KCbau3PR0ezokyUkQFviuT
// |sk_live_at388F51Dyf6PDAx5zqMxm8c1WazqjGx
// wk_live_SR1WUeT9v4awzvxgYDFIt0VsfIj1FS

 export const CHAINS = [
    // {
    //     NAME: "BNB Chain",
    //     RPC_URL:"https://bsc-testnet-rpc.publicnode.com",
    //     SYMBOL:"BNB",
    //     IMAGE:bsc,
    //        // RPC_URL:[
    //         //     "https://neat-fragrant-crater.bsc-testnet.quiknode.pro/b5baa8a7cdddef6b65cdb7ac9f8e598244df6e4f/",
    //         //     "https://bsc-testnet.publicnode.com",
    //         //     "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    //         //     "https://bsc-testnet.blockpi.network/v1/rpc/public"],
    //     CHAIN_ID:97,
    //     TRANSACTION : "https://testnet.bscscan.com/tx",
    //     TokenTracker : "https://testnet.bscscan.com/token/",
    //     Testnet : "https://testnet.bscscan.com/address",
    //     // MULTICALL: "0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe",
    //     EndBlock : "https://testnet.bscscan.com/block/countdown"

    // },
    // {
    //     NAME: "Ethereum",
    //     RPC_URL:"https://rpc2.sepolia.org",
    //     CHAIN_ID:11155111,
    //     IMAGE: eth,
    //     TRANSACTION : "https://sepolia.etherscan.io/tx/",
    //     TokenTracker : "https://sepolia.etherscan.io/token/",
    //     Testnet : "https://sepolia.etherscan.io/address/", 
    //     EndBlock : "https://sepolia.etherscan.io/block/countdown"
        
    // },
    // {
    //     NAME: "Avalanche",
    //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
    //     CHAIN_ID:43113,
    //     SYMBOL:"AVAX",
    //     IMAGE: avax,
    //     TRANSACTION : "https://testnet.snowtrace.io/tx/",
    //     TokenTracker : "https://testnet.snowtrace.io/token/",
    //     Testnet : "https://testnet.snowtrace.io/address/",
    //     EndBlock : "https://testnet.snowtrace.io/block/countdown"
    // },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:421614,
    //     IMAGE: arbitrum,
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/", 
    //     Testnet : "https://arbiscan.io/address/",
    //     EndBlock : "https://arbiscan.io/block/countdown"
    // },
    // {
    //     NAME: "zkSync",
    //     RPC_URL:"https://testnet.era.zksync.dev",
    //     CHAIN_ID:280,
    //     IMAGE: zkSync,
    //     TRANSACTION : "https://explorer.zksync.io/tx/",
    //     TokenTracker : "https://explorer.zksync.io/token/",
    //     Testnet : "https://explorer.zksync.io/address/",
    //     EndBlock : "https://explorer.zksync.io/block/countdown"
    // },

    //   {
    //     NAME: "Sepolia",
    //     RPC_URL:"https://sepolia.infura.io/v3/",
    //     CHAIN_ID:11155111,
    //     IMAGE: zkSync,
    //     TRANSACTION : "https://explorer.zksync.io/tx/",
    //     TokenTracker : "https://explorer.zksync.io/token/",
    //     Testnet : "https://explorer.zksync.io/address/",
    //     EndBlock : "https://explorer.zksync.io/block/countdown"
    // },




    {
      NAME: "BSC-TEST",
      CHAIN_ID :  97, 
      RPC_URL: "https://tame-lively-thunder.bsc-testnet.quiknode.pro/13404d9b95dcfe879d38e102ab89e041743a2fe7/",
      IMAGE: zkSync,
      TRANSACTION : "https://explorer.zksync.io/tx/",
      TokenTracker : "https://explorer.zksync.io/token/",
      Testnet : "https://explorer.zksync.io/address/",
      EndBlock : "https://explorer.zksync.io/block/countdown"
  },

//   {
//     NAME: "BSC-TEST",
//     CHAIN_ID :  56, 
//     RPC_URL:"https://bsc-pokt.nodies.app",
//     // RPC_URL: "https://tame-lively-thunder.bsc-testnet.quiknode.pro/13404d9b95dcfe879d38e102ab89e041743a2fe7/",
//     IMAGE: zkSync,
//     TRANSACTION : "https://explorer.zksync.io/tx/",
//     TokenTracker : "https://explorer.zksync.io/token/",
//     Testnet : "https://explorer.zksync.io/address/",
//     EndBlock : "https://explorer.zksync.io/block/countdown"
// },




  
];

//local
export const API_URL = "http://localhost:7097"

//demo
// export const API_URL = "https://backend-chainpad.maticz.in/"

//staging
// export const API_URL = "https://apistaging.chainpad.app/"

export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

export const COINS = ["BNB" , "DAI" , "USDT" , "USDC" , "BNB" , "MATIC" , "AVAX" , "CRO" , "FTM"];

export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"

export const REFERRER_ADDRESS = "0x754a9c737dF3224B0A200408120c07F74Cef112f"

// 1 day, 7 days, 30 days, 1 year, 5 years
export const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]

export const MINIMUM_LIQUIDITY = 0.000000000000001

// export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"

export const BURN = "0x000000000000000000000000000000000000dEaD";

// export const BLOCKS_PER_YEAR = 10512000;

export const BSC_BLOCK_TIME = 3

export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000

export const INTERVAL = 300; // 5 minutes





