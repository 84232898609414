import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import cascading from "../assests/css/HistoryCanvaStyle.module.css";
import { IoMdClose } from "react-icons/io";
import ClaimModal from "../modals/ClaimModal";
import { NumberChange } from "../hooks/useCommon";
import { UseFetchPredictionClaim } from '../hooks/usePrediction'
import { isEmpty } from "../lib/isEmpty";

const HistoryCanva = (props) => {
  console.log("propspropspropsprops", props);

  const [activeTabs, setActiveTabs] = useState(1);
  const [filterTabs, setFilterTabs] = useState(1);
  const [confirmShow, setCofirmShow] = useState(false);
  const [claimRecord, setClaimRecord] = useState({})
  const [isClaim, setIsClaim] = useState(false)

  const [tabHead, setTabHead] = useState([
    {
      id: 1,
      heading: "Rounds",
    },
    {
      id: 2,
      heading: "PNL",
    },
  ]);
  const [filterHead, setFilterHead] = useState([
    { id: 1, heading: "All" },
    { id: 2, heading: "Collected" },
    { id: 3, heading: "Uncollected" },
  ]);

  const handleSelectTabs = (getId) => {
    setActiveTabs(getId);
  };

  const handleFilterTabs = (getId) => {
    setFilterTabs(getId);
  };

  const handleClaim = async (position) => {
    setIsClaim(true)
    const FetchData = await UseFetchPredictionClaim(position)
    setClaimRecord(FetchData)
    props?.FetchHistory()
    setIsClaim(false)
  };

  return (
    <div>
      <Offcanvas
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        placement="end"
        className={`${cascading.canvaInner} canvaInner historyCanva`}
      >
        <Offcanvas.Header>
          <div
            className={`${cascading.header} d-flex align-items-center justify-content-between`}
          >
            <h5 className={`${cascading.title} mb-0`}>History</h5>
            <button
              className={`${cascading.closeButton}`}
              onClick={() => props.handleClose()}
            >
              <IoMdClose />
            </button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`${cascading.body}`}>
            <div className={`${cascading.tabs}`}>
              <div className={`${cascading.tabRow} row mx-auto`}>
                <div
                  className={`${cascading.tabsWrap} d-flex align-items-center`}
                >
                  {tabHead.map((val) => (
                    <div
                      className={`${cascading.tabHead} ${activeTabs === val.id ? `tabHeadActive` : ""
                        } col-6 px-0 d-flex justify-content-center`}
                      id={1}
                      onClick={() => handleSelectTabs(val.id)}
                    >
                      {val.heading}
                    </div>
                  ))}
                </div>
              </div>
              {activeTabs === 1 && (
                <div className={`${cascading.tabsContent}`}>
                  <div className={`${cascading.filter} filter pt-0`}>
                    <p className={`${cascading.filterLabel} mb-2`}>Filter</p>
                    <div
                      className={`${cascading.filterHeadWrap} d-flex align-items-center gap-3`}
                    >
                      {filterHead.map((filterVal) => (
                        <div className={`${cascading.filterValues}`}>
                          <div className={`d-flex align-items-center gap-2`}>
                            <div
                              className={`${cascading.filterChoose} ${filterTabs === filterVal.id
                                ? "filterChooseActive"
                                : ""
                                }`}
                              onClick={() => handleFilterTabs(filterVal.id)}
                            ></div>
                            <p className={`${cascading.filterTitle}  mb-0 `}>
                              {filterVal.heading}
                            </p>
                          </div>{" "}
                        </div>
                      ))}
                    </div>{" "}
                  </div>{" "}
                  <div className={`${cascading.filterContentWrap} `}>
                    {filterTabs === 1 && (
                      <div className={`${cascading.filterContent}`}>
                        <div
                          className={`${cascading.round} ${cascading.round2}`}
                        >
                          <div className={`row align-items-start`}>

                            {props?.ListData && props?.ListData?.length > 0 ? props?.ListData?.map((val) => {
                              let won
                              if (!isEmpty(props?.wonRecord)) {
                                won = props?.wonRecord?.find((vals) => (vals.epoch == val.epoch))
                              }

                              return (
                                <>
                                  <div className={`col-3 mb-3`}>
                                    <div>
                                      {" "}
                                      <p className={`${cascading.title} mb-0`}>
                                        Round
                                      </p>
                                      <p className={`${cascading.value} mb-0`}>
                                        {val?.epoch}
                                      </p>
                                    </div>
                                  </div>
                                  <div className={`col-9 mb-3`}>
                                    <div className={`${cascading.result} `}>
                                      <p className={`${cascading.title} mb-0`}>
                                        Your Result
                                      </p>

                                      {!isEmpty(won) ? <p className={`${cascading.resultValue} ${cascading.win} mb-0`}>
                                        {" "}
                                        {(val?.amount * val?.Uppayout) / 10 ** 18}
                                      </p> :
                                        <p className={`${cascading.resultValue}  mb-0`}>
                                          {" "}
                                          {(val?.amount * val?.Uppayout) / 10 ** 18}
                                        </p>
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            }) :

                              <div className={`${cascading.noPrediction}`}>

                                <p className={`${cascading.title} mb-0`}>
                                  No prediction history available
                                </p>
                                <p className={`${cascading.desc} mb-0`}>
                                  If you are sure you should see history here, make
                                  sure you're connected to the correct wallet and try
                                  again.
                                </p>
                              </div>}
                          </div>
                        </div>
                      </div>
                    )}
                    {filterTabs === 2 && (
                      <div className={`${cascading.filterContent}`}>
                        <div className={`${cascading.round}`}>

                          {props?.Active && props?.Active?.length > 0 ? props?.Active?.map((val) => {

                            if (val?.claimed !== false) {
                              return (
                                <div className="row align-items-start">
                                  <div className={`col-3 mb-3`}>
                                    <div>
                                      {" "}
                                      <p className={`${cascading.title} mb-0`}>
                                        Round
                                      </p>
                                      <p className={`${cascading.value} mb-0`}>
                                        {val?.epoch}
                                      </p>
                                    </div>
                                  </div>
                                  <div className={`col-9 mb-3`}>
                                    <div className={`${cascading.result} `}>
                                      <p className={`${cascading.title} mb-0`}>
                                        Your Result
                                      </p>

                                      <p className={`${cascading.resultValue} ${cascading.win} mb-0`}>
                                        {" "}
                                        {(val?.amount * val?.Uppayout) / 10 ** 18}
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              )
                            }

                          }) :
                            <div className={`${cascading.noPrediction}`}>

                              <p className={`${cascading.title} mb-0`}>
                                No prediction history available
                              </p>
                              <p className={`${cascading.desc} mb-0`}>
                                If you are sure you should see history here, make
                                sure you're connected to the correct wallet and try
                                again.
                              </p>
                            </div>

                          }     </div>
                      </div>
                    )}
                    {filterTabs === 3 && (
                      <div className={`${cascading.filterContent}`}>

                        <div className={`${cascading.round}`}>
                          {props?.wonRecord && props?.wonRecord?.length > 0 ? props?.wonRecord?.map((val) => {
                            let Won
                            if (!isEmpty(props?.wonRecord)) {
                              Won = props?.wonRecord?.find((vals) => (vals.epoch == val.epoch))
                            }
                            if (val?.claimed == false) {
                              return (
                                <div className="row align-items-start  mb-3">
                                  <div className={`col-3`}>
                                    <div>
                                      {" "}
                                      <p className={`${cascading.title} mb-0`}>
                                        Round
                                      </p>
                                      <p className={`${cascading.value} mb-0`}>
                                        {val?.epoch}
                                      </p>
                                    </div>
                                  </div>
                                  <div className={`col-5`}>
                                    <div className={`${cascading.result} `}>
                                      <p className={`${cascading.title} mb-0`}>
                                        Your Result
                                      </p>

                                      {!isEmpty(Won) ? <p className={`${cascading.resultValue} ${cascading.win} mb-0`}>
                                        {" "}

                                        {(val?.amount * val?.Uppayout) / 10 ** 18}
                                      </p> :
                                        <p className={`${cascading.resultValue} mb-0`}>
                                          {" "}
                                          {(val?.amount * val?.Uppayout) / 10 ** 18}
                                        </p>
                                      }
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className={`${cascading.claim_button}`}>
                                      <button className={cascading.violet_button} disabled={isClaim || !val.isClaimable} onClick={() => handleClaim(val.epoch)}>
                                        Claim
                                      </button>
                                    </div>

                                  </div>
                                </div >
                              )
                            }
                          }) :
                            <div className={`${cascading.noPrediction}`}>

                              <p className={`${cascading.title} mb-0`}>
                                No prediction history available
                              </p>
                              <p className={`${cascading.desc} mb-0`}>
                                If you are sure you should see history here, make
                                sure you're connected to the correct wallet and try
                                again.
                              </p>
                            </div>}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </div>
              )}{" "}
              {activeTabs === 2 && (
                <div className={`${cascading.pnlContent}`}>
                  <div className={`${cascading.historyDetails}`}>
                    <h5 className={`${cascading.title} mb-0`}>Your History</h5>
                    <div
                      className={`row mt-3 mx-auto d-flex align-items-center`}
                    >
                      <div className={`col-6 px-0`}>
                        <div
                          className={`${cascading.donut} d-flex flex-column align-items-center justify-content-center`}
                        >
                          <p className={`${cascading.wonText} mb-0 `}>Won</p>
                          <p className={`${cascading.percentage} `}>{props?.wonRecord?.length > 0 ? props?.wonRecord?.length : 0}/{props?.pnlRecord?.length > 0 ? props?.pnlRecord?.length : 0}</p>
                          <p className={`${cascading.wonText} mb-0`}>{props?.pnlRecord?.length > 0 ? (((Number(props?.wonRecord?.length) || 0) / (Number(props?.pnlRecord?.length) || 0)) * 100).toFixed(2) : "0.0"}%</p>
                        </div>
                      </div>
                      <div className={`col-6 px-0 `}>
                        <div>
                          <p className={`${cascading.violetText} mb-1`}>
                            Net results
                          </p>

                          <h4 className={`${cascading.bnbValue} mb-1`}>
                            <p>{props?.common?.NetResult !== undefined && props?.common?.NetResult !== null ? props.common.NetResult : 0}</p>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className={`${cascading.mainText} mt-4 mb-1`}>
                        Average return / Round
                      </p>
                      <p className={`${cascading.bnbDetails} mb-1`}>
                        <p>{props?.common?.AvgValue !== undefined && props?.common?.AvgValue !== null ? props.common.AvgValue : 0}</p>
                      </p>
                    </div>
                  </div>{" "}
                  <div className={`${cascading.historyDetails}`}>

                    <p className={`${cascading.mainText} mb-1 mt-4 pt-2`}>
                      Won
                    </p>
                    <div className={`row mt-2`}>

                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            {`${props?.wonRecord?.length ? props.wonRecord.length : 0} rounds`}
                          </p>
                          <p className={`${cascading.roundPercent} `}>
                            {props?.pnlRecord?.length > 0 ? (((Number(props?.wonRecord?.length) || 0) / (Number(props?.pnlRecord?.length) || 0)) * 100).toFixed(2) : "0.0"}%
                          </p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>

                          <p className={`${cascading.roundCount} mb-0`}>

                            {props?.wonRecord?.length > 0 ? props?.wonRecord?.reduce((total = 0, a) => {
                              return total = NumberChange(total) + NumberChange(a.amount)
                            }, 0) / 10 ** 18 : 0} BNB
                          </p>

                          {/* {props?.wonRecord && <p className={`${cascading.roundPercent}`}>~$ {(props?.wonRecord?.reduce((total = 0, a) => {
                            return total = NumberChange(total) + NumberChange(a.BnbValues)
                          }, 0)).toFixed(4)}</p>} */}

                          <p className={`${cascading.roundPercent}`}>
                            ~$ {props?.wonRecord?.length > 0
                              ? props.wonRecord.reduce((total, a) => total + NumberChange(a.BnbValues), 0).toFixed(4)
                              : 0}
                          </p>

                        </div>
                      </div>


                    </div>{" "}
                    <p className={`${cascading.mainText} mb-1 mt-4 `}>Lost</p>
                    <div className={`row mt-2`}>

                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            {`${props?.lossRecord?.length ? props.lossRecord.length : 0} rounds`}
                          </p>
                          <p className={`${cascading.roundPercent} `}>
                            {props?.pnlRecord?.length > 0 ? (((Number(props?.lossRecord?.length) || 0) / (Number(props?.pnlRecord?.length) || 0)) * 100).toFixed(2) : "0.0"}%
                          </p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            {props?.lossRecord?.length > 0 ? props?.lossRecord?.reduce((total = 0, a) => {
                              return total = NumberChange(total) + NumberChange(a.amount)
                            }, 0) / 10 ** 18 : 0} BNB
                          </p>

                          {/* {props?.lossRecord && <p className={`${cascading.roundPercent}`}>~$ {(props?.lossRecord?.reduce((total = 0, a) => {
                            return total = NumberChange(total) + NumberChange(a.BnbValues)
                          }, 0)).toFixed(4)}</p>} */}

                          {props?.lossRecord && props.lossRecord.length > 0 ? (
                            <p className={`${cascading.roundPercent}`}>
                              ~$ {props.lossRecord.reduce((total, a) => {
                                return total + NumberChange(a.BnbValues);
                              }, 0).toFixed(4)}
                            </p>
                          ) : (
                            <p className={`${cascading.roundPercent}`}>~$ 0</p>
                          )}

                        </div>
                      </div>


                    </div>{" "}
                    <p className={`${cascading.mainText} mb-1 mt-4 `}>
                      Entered
                    </p>
                    <div className={`row mt-2`}>

                      <div className={`col-4`}>
                        <div>
                          <p className={`${cascading.roundCount} mb-0`}>
                            {/* {`${props?.pnlRecord?.length && props?.pnlRecord?.length} rounds`} */}
                            {`${props?.pnlRecord?.length ? props.pnlRecord.length : 0} rounds`}
                          </p>

                          <p className={`${cascading.roundPercent} `}>Total</p>
                        </div>
                      </div>{" "}
                      <div className={`col-8`}>
                        <div>

                          <p className={`${cascading.roundCount} mb-0`}>
                            {props?.pnlRecord
                              ? props.pnlRecord.reduce((total, a) => {
                                return total + NumberChange(a.amount);
                              }, 0) / 10 ** 18
                              : 0} BNB
                          </p>

                          {/* {props?.pnlRecord && <p className={`${cascading.roundPercent}`}>~$ {(props?.pnlRecord?.reduce((total = 0, a) => {
                            return total = NumberChange(total) + NumberChange(a.BnbValues)
                          }, 0)).toFixed(4)}</p>} */}

                          <p className={`${cascading.roundPercent}`}>
                            ~$ {props?.pnlRecord && props.pnlRecord.length > 0
                              ? props.pnlRecord.reduce((total, a) => {
                                return NumberChange(total) + NumberChange(a.BnbValues);
                              }, 0).toFixed(4)
                              : 0
                            }
                          </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {confirmShow && (
        <ClaimModal
          show={confirmShow}
          onHide={() => {
            setCofirmShow(false);
          }}
        />
      )}
    </div>
  );
};

export default HistoryCanva;
