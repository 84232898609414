import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { IoMdClose } from "react-icons/io";
import { LotterytokenSymbol } from "../config/lottery";
import { BuyTickets } from "../hooks/useLottery";

export default function Editticketmodal(props) {
  // state
  const [editticketmodal, setEditticketmodal] = useState(true);
  const [random, setRandom] = useState(false);
  const [otp, setOtp] = useState('');
  const [accountInfo, setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))

  const [ticketNumber, setticketNumber] = useState([]);
  const [showticketNumber, setshowticketNumber] = useState("");



  useEffect(() => {
    var ticketArray = [];
    if (props?.ticketNumber) {
      setshowticketNumber(String(props?.ticketNumber));
      ticketArray.push(props?.ticketNumber);
      setticketNumber(ticketArray)

    }

  }, [props])


  const buyTickets = async () => {

    var buynow = await BuyTickets(ticketNumber, accountInfo)

  }


  const NonrandomNumber = async () => {

    var ticketArray = [];
    for (var i = 0; i < parseInt(props?.LotteryAmount); i++) {
      var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
      var randomNo =
        parseFloat(randomNo.toString().split("").reverse().join("")) *
        Math.sign(randomNo);
      var final = randomNo + 1000000;
      ticketArray.push(final);
      setticketNumber(ticketArray)
      setshowticketNumber(String(final))

    }


  }


  const randomNumber = async () => {

    var ticketArray = [];
    if (props?.ticketNumber) {
      setshowticketNumber(String(props?.ticketNumber))
      ticketArray.push(props?.ticketNumber);
      setticketNumber(ticketArray)

    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={editticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Edit Numbers</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <div className={`mt-3 ${cascading.secrow}`}>
              <p
                className={`mb-0 d-flex align-items-center ${cascading.labeledit}`}
              >
                Total Cost:
              </p>
              <p className={`mb-0 ${cascading.labelsedit}`}>
                {props?.LotteryAmount} {LotterytokenSymbol}
              </p>
            </div>

            <p className="mt-3">Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits: 0-9</p>
            {!random ?
              <button
                className={`btn ${cascading.connectwalletbtn}`}
                type="button"
                onClick={() => { setRandom(true); NonrandomNumber() }}
              >
                <span>{!random ? "Nonrandomize" : "Randomize"}</span>
              </button> :

              <button
                className={`btn ${cascading.connectwalletbtn}`}
                type="button"
                onClick={() => { setRandom(false); randomNumber() }}
              >
                <span>{!random ? "Nonrandomize" : "Randomize"}</span>
              </button>}
            <p className="mt-3">#001</p>

            {random ?

              <>

                <div className={`${cascading.inputsec} `}>


                  <div className={`${cascading.balancerow}  d-flex align-items-center justify-content-between`}>


                    {/* <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
                      <span>
                        <input className={`${cascading.inputticket} `} type="text"
                          value={showticketNumber[1]}
                          maxLength={1}
                        />
                      </span>
                      <span>
                        <input className={`${cascading.inputticket}`} type="text"
                          value={showticketNumber[2]}
                          maxLength={1} />

                      </span>
                      <span>

                        <input className={`${cascading.inputticket}`} type="text"
                          value={showticketNumber[3]}
                          maxLength={1} />

                      </span>
                      <span>
                        <input className={`${cascading.inputticket}`} type="text"
                          value={showticketNumber[4]}
                          maxLength={1} />

                      </span>
                      <span>
                        <input className={`${cascading.inputticket}`} type="text"
                          value={showticketNumber[5]}
                          maxLength={1} />

                      </span>
                      <span>
                        <input className={`${cascading.inputticket}`} type="text"
                          value={showticketNumber[6]}
                          maxLength={1} />

                      </span>

                    </p> */}
                    <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[1]}
                    </div>
                    <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[2]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[3]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[4]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[5]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[6]}
                    </div>

                  </div>



                </div>
              </>
              :
              <>
                <div className={`${cascading.inputsec} `}>



                  <div className={`${cascading.balancerow} d-flex align-items-center justify-content-between`}>
                    <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[1]}
                    </div>
                    <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[2]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[3]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[4]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[5]}
                    </div>   <div className={`${cascading.numberWrapper}`}>
                      {showticketNumber[6]}
                    </div>

                    {/* <p className={`${cascading.inputlabelticket} mb-0 mt-0`}>
                        <span>
                            <input className={`${cascading.inputticket} `} type="text"
                            value={showticketNumber[1]}
                            readOnly={true}
                            maxLength={1} />
                          
                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text"
                         value={showticketNumber[2]}
                         readOnly={true}
                        maxLength={1} />

                        </span>
                        <span>
                            
                        <input className={`${cascading.inputticket}`} type="text" 
                         value={showticketNumber[3]}
                         readOnly={true}
                        maxLength={1}/>

                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" 
                         value={showticketNumber[4]}
                         readOnly={true}
                        maxLength={1}/>
                            
                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text" 
                         value={showticketNumber[5]}
                         readOnly={true}
                        maxLength={1}/>

                        </span>
                        <span>
                        <input className={`${cascading.inputticket}`} type="text"
                         value={showticketNumber[6]}
                         readOnly={true}
                        maxLength={1} />

                        </span>

                    </p> */}
                    {/* <div className="custom_AutoInput">
                    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
                    </div> */}

                  </div>
                </div>
              </>
            }

            <hr />




            <hr />






            <button
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={() => buyTickets()}
            >
              Confirm and Buy
            </button>


          </Modal.Body>
        </Modal>
      </div>

    </>
  );
}
