import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";

import ReactDatatable from "@ashvin27/react-datatable";
import {
  CloseAllLottery,
  CloseLottery,
  DrawnfinalNumberandReward,
  ViewAllLottery,
} from "../../hooks/useLottery";
import moment from "moment";

// Import Swiper React components

// import required modules

// Install Swiper modules

const Lotterylist = () => {
  const [isConnectWallet, setIsConnectWallet] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allrecords, setrecords] = useState([]);
  const [accountInfo, setaccountInfo] = useState(
    sessionStorage.getItem("accountInfo")
  );

  useEffect(() => {
    AllLottery();
  }, []);

  const AllLottery = async () => {
    var alllottey = await ViewAllLottery();
    console.log("alllottey>>>>>",alllottey);
    setrecords(alllottey)
}


console.log('allrecords',allrecords)

const Close_Lottery = async(lotteryid) =>{
    var Close = await CloseLottery(lotteryid,accountInfo);
    if(Close){
window.location.reload()
    }
  };

  const DistributeReward = async (lotteryid) => {
    var reward = await DrawnfinalNumberandReward(lotteryid, accountInfo);
    if (reward) {
      window.location.reload();
    }
    
}
//   const config = {
//     page_size:10,
//     length_menu:[10,20,50],
//     button: {
//         excel: false,
//         print: false,
//         extra: false,
//     },
//     show_filter: false,
//     show_length_menu: false,
//     show_pagination: true,
//     show_info:true

// }
let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: false,
      print: false,
    },
    show_pagination: true,
    show_info: true,
    show_length_menu: false,
    show_filter: false,
  };

  const columns = [
    {
        key: "lotteryid",
        text: "Lottery ID",
        width:120,
        className: "lotteryid text-center w_120",
        align: "center",
        sortable: true,
        cell: (record, index) => {
            return (
                // <>
                // {console.log('valasd', record,record.startTime, index)}
                // </>
                <>
                    {/* <div className={`${cascading.detailtablepools}`}> */}
                    <p>#{index +1}</p> 
                        
                    {/* </div> */}

                </>
            );
        }


    },
    {
        key: "startTime",
        text: "Start Time",
        className: "starttime text-center w_180",
        width:180,
        align: "center",
        sortable: true,
        cell: record => {
            return (
                <>
               
               <p>{moment(String(new Date(Number(record.startTime)* 1000))).format('LLLL')} </p>

                {/* <p>{String(new Date(Number(record.startTime)* 1000))} </p> */}
                </>
            );
        }
    },
    {
        key: "endTime",
        text: "End Time",
        className: "endtime text-center w_180",
        width:180,
        align:"center",
        sortable: true,
        cell: record => {
            return (
                <>
                {/* <p>{moment(record.endTime).format('LLLL')} </p> */}



                 <p>{moment(String(new Date(Number(record.endTime)* 1000))).format('LLLL')} </p>
               

                </>
            );
        }
    },
    {
        key: "priceTicketInCake",
        text: "Ticket Price",
        className: "ticketprice text-center w_120",
        align: "center",
        width:120,
        sortable: true,
        cell: record => {
            return (
                <>
                   <p>{parseFloat(record?.priceTicketInCake) / 10 ** 18}</p>

                </>
            );
        }
    },
    {
        key: "action",
        text: "Action",
        className: "action text-center w_150",
        width:150,
        sortable: true,
        align: "center",
        cell: (record, index) => {
            console.log("record.status",record.status);
            if (record.status == 0 && new Date(record.endDate) > new Date()){
                return (
                    <>
                       <button className={`${cascading.connectwalletbtn} ${cascading.connectwalletbtnnew}`}>Open</button>
    
                    </>
                )
             }else if(record.status == 1){ //1
                return (
                    <>
                       <button className={`${cascading.connectwalletbtn} ${cascading.connectwalletbtnnew}`} onClick={()=>Close_Lottery(index+1)}> Close Lottery</button>
    
                    </>
                )
             }else if(record.status == 2){
                return (
                    <>
                       <button className={`${cascading.connectwalletbtn} ${cascading.connectwalletbtnnew}`} onClick={()=>DistributeReward(index+1)}>Distribute Rewards</button>
    
                    </>
                )

             }else{
                return (
                    <>
                       <button className={`${cascading.connectwalletbtn}  ${cascading.connectwalletbtnnew}`}>Completed</button>
    
                    </>
                )

             }
            
        }
      },
    ,
  ];

  // const records = [
  //     {
  //         "lotteryid": "001",
  //         "starttime": "22 Jan 2024 12:00 AM",
  //         "endtime": "25 May 2024 12:00 AM",
  //         "ticketprice": "20",
  //         "action": "Completed"
  //     },
  //     {
  //         "lotteryid": "002",
  //         "starttime": "22 Jan 2024 12:00 AM",
  //         "endtime": "25 May 2024 12:00 AM",
  //         "ticketprice": "20",
  //         "action": "Completed"
  //     },
  //     {
  //         "lotteryid": "003",
  //         "starttime": "22 Jan 2024 12:00 AM",
  //         "endtime": "25 May 2024 12:00 AM",
  //         "ticketprice": "20",
  //         "action": "Completed"
  //     },

  // ];

  return (
    <div>
      <Header />
      <div
        className={`${cascading.innerpages} ${cascading.predictionPage} ${cascading.lotteryPage}`}
      >
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Lottery List</h3>
          <div className="mt-4">
          <div className={`${cascading.tabstyle} card_table`} id='tablestyle1'>


<ReactDatatable
    // data={data}
    className={`${cascading.datatablestyle}`}
    config={config}
    columns={columns}
    records={allrecords}

/>
</div>
          </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Lotterylist;
