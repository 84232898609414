import React, { useState, useEffect } from "react";
import cascading from "../assests/css/PredictionFlipCard.module.css";
import { FaArrowDown, FaArrowLeft, FaArrowUp } from "react-icons/fa";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import bscicon from "../assests/images/bscicon.png";
import Walletmodal from "../modals/walletmodal";
import { UseBetBull, UseBetBear, EstimateDefaultGasFees, MinBitAmount } from "../hooks/usePrediction";
import { GetCurrencyBalance } from "../hooks/useTokens";
import RangeSlider from "react-bootstrap-range-slider";
import "bootstrap/dist/css/bootstrap.css";
import "react-range-slider-input/dist/style.css";
import { isEmpty } from "../lib/isEmpty";

const PredictionFlipCard = ({
  setFlipCard,
  isConnectWallet,
  setIsConnectWallet,
  setIsConfirm,
  isDownClick,
  setIsDownClick,
  setEnterDown,
  upDown,
  setUpDown,
  FetchHistory,
  Id,
}) => {
  console.log("FetchHistoryFetchHistory", upDown);
  // const [upDown, setUpDown] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [up, setUp] = useState({});
  const [down, setDown] = useState({});
  const [errors, setError] = useState();
  const [balance, setBalance] = useState();
  const [upValue, setupValue] = useState(0);
  const [downValue, setDownValue] = useState(0);
  const [isUP, setIsUP] = useState(false)
  const [isDown, setIsDown] = useState(false)
  const [percentage, setPercentage] = useState(60);
  const { walletaddress } = useSelector((state) => (state.wallet))

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    var bal = await GetCurrencyBalance();
    setBalance(bal);
  };

  const validation = async () => {
    let errors = {};
    let MinAmt = await MinBitAmount()
    if (isEmpty(up?.Upamount)) {
      toast.error('Field cannot be empty');
      errors['Upamount'] = 'Field cannot be empty'
    }
    else if (balance < up?.Upamount) {
      toast.error('Insufficient Balance.');
      errors['Upamount'] = 'Insufficient Balance.'
    }

    else if (typeof MinAmt !== 'number' || typeof balance !== 'number') {

      toast.error('Invalid types for minAmt or balance');
      errors['Upamount'] = 'Invalid types for minAmt or balance'
    }

    else if (MinAmt > balance) {

      toast.error('Insufficient Balance.');
      errors['Upamount'] = 'Insufficient Balance'
    }
    return errors
  }

  const Downvalidation = async () => {
    let errors = {};
    let MinAmt = await MinBitAmount()
    if (isEmpty(down?.Downamount)) {
      toast.error('Field cannot be empty');
      errors['Downamount'] = 'Field cannot be empty'
    }
    else if (balance < down?.Downamount) {
      toast.error('Insufficient Balance.');
      errors['Downamount'] = 'Insufficient Balance.'
    }
    else if (MinAmt > balance) {
      toast.error('Insufficient Balance....');
      errors['Downamount'] = 'Insufficient Balance'
    }
    return errors
  }

  const HandleConfirm = async () => {
    setIsUP(true)
    var value = await validation()
    if (isEmpty(value)) {
      let FetchUpdata = await UseBetBull(Id, up?.Upamount);
      if (FetchUpdata.status == true) {
        setError({});
        setUp({ Upamount: "" });
        setupValue(0)
        FetchHistory()
        setIsUP(false)
        setFlipCard(false);
      } else {
        if (FetchUpdata?.error?.message?.includes('Can only bet once per round')) {
          toast.error('Can only bet once per round')
        }
      }
    }
    else {
      setError(value)
    }

    setIsUP(false)
  };

  const HandleDownConfirm = async () => {
    setIsDown(true)
    var values = await Downvalidation()
    if (isEmpty(values)) {
      let FetchDowndata = await UseBetBear(Id, down?.Downamount);
      if (FetchDowndata.status == true) {
        setError({});
        setDown({ Downamount: "" });
        setDownValue(0)
        FetchHistory()
        setIsDown(false)
        setFlipCard(false);
      } else {
        if (FetchDowndata?.error?.message?.includes('Can only bet once per round')) {
          toast.error('Can only bet once per round')
        }
      }
    }
    else {
      setError(values)
    }
    setIsDown(false)
  };

  const NumANdDotOnly = (data) => {
    var data = data.toString();
    var str = data
      ? data.includes(".")
        ? data.split(".").length >= 3
          ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
          : data
        : data
      : data;
    return str.toString().replace(/[^0-9\.]/g, "");
  };

  const Uphandlechange = (e) => {
    try {
      //if(value == balance) max
      setError({});
      let { name, value } = e.target;
      value = NumANdDotOnly(value);
      var SetBalanceData = (value / balance) * 100
      SetBalanceData = (SetBalanceData).toFixed(2)

      if (SetBalanceData <= 100) {
        setupValue(SetBalanceData)
        setUp({ ...up, [name]: NumANdDotOnly(value) });
      }
    } catch (err) {
      console.log("Uphandlechange_err", err);
    }
  };


  const Downhandlechange = (e) => {
    try {

      setError({});
      var { name, value } = e.target;
      var SetBalanceData = (value / balance) * 100
      SetBalanceData = (SetBalanceData).toFixed(2)

      if (SetBalanceData <= 100) {
        setDownValue(SetBalanceData)
        setDown({ ...down, [name]: NumANdDotOnly(value) });
      }

    } catch (err) {
      console.log("Downhandlechange_err", err);
    }
  };


  const UpBalance = async (index) => {
    if (index == 100) {
      setupValue(index);
      let Estimatefee = await EstimateDefaultGasFees(balance)
      let Maxbalance = balance - Estimatefee
      var balanceAmount = (Maxbalance * index) / 100

      var name = "Upamount";
      setUp({ ...up, [name]: (balanceAmount).toFixed(4) });
    } else {
      setupValue(index);
      var SetBalanceData = ((balance * index) / 100).toFixed(4);
      var name = "Upamount";
      setUp({ ...up, [name]: SetBalanceData });
    }
  };

  const DownBalance = async (index) => {
    if (index == 100) {
      setDownValue(index);
      let Estimatefee = await EstimateDefaultGasFees()
      let Maxbalance = balance - Estimatefee
      var balanceAmount = (Maxbalance * index) / 100

      var name = "Downamount";
      setDown({ ...down, [name]: (balanceAmount).toFixed(4) });
    } else {
      setDownValue(index);
      var SetBalanceData = ((balance * index) / 100).toFixed(4);
      var name = "Downamount";
      setDown({ ...down, [name]: SetBalanceData });
    }
  };

  const [value1, setValue1] = useState(1);
  const handleStyle1 = {
    color: "#fff",
    fontSize: 12,
    width: 32,
    height: 22,
  };
  const labels1 = ["1x", "5x", "10x", "15x", "20x", "25x"]; // Example labels
  const styles = {
    down_progress_fill: {
      position: "absolute",
      height: "7px",
      background: "#ed4b9e",
      zIndex: "2",
      borderRadius: "10px",
      width: `${downValue}%`,
    },
    up_progress_fill: {
      position: "absolute",
      height: "7px",
      background: "#7645d9",
      zIndex: "2",
      borderRadius: "10px",
      width: `${upValue}%`,
    },
    up_range_value_text: {
      fontSize: "10px",
      position: "absolute",
      zIndex: "3",
      top: "10px",
      color: "#7645d9",
      left: `${upValue - 2.5}%`,
    },
    down_range_value_text: {
      fontSize: "10px",
      position: "absolute",
      zIndex: "3",
      top: "10px",
      color: "#ed4b9e",
      left: `${downValue - 2.5}%`,
    },
  };

  return (
    <>
      {isDownClick ? (
        <div
          className={`${cascading.predictionFlipCardPage} ${cascading.enterDown} enterdown`}
        >
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => {
                      setDown({ Downamount: "" });
                      setUp({ Upamount: "" });
                      setFlipCard(false);
                      setDownValue(0)
                      setupValue(0)

                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                      setupValue(0)
                      setDownValue(0)
                      setUp({ Upamount: "" })
                      setDown({ Downamount: "" });
                    }}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                      setDownValue(0)
                      setupValue(0)
                      setUp({ Upamount: "" })
                      setDown({ Downamount: "" });
                    }}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>

                <input
                  type="text"
                  className={cascading.inputField}
                  name="Downamount"
                  id="Downamount"
                  value={down?.Downamount || ''}
                  onChange={(e) => Downhandlechange(e)}
                />
              </div>
              {/* upvalue */}
              <div className={`${cascading.bottomSection}`}>
                {/* <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div> */}
                <div className="down_custom_range_slider   position-relative  mt-3">
                  {/* <div
                    className="down_progress_fill "
                    style={{ width: `${downValue - 0.5}%` }}
                  ></div> */}
                  <div
                    // className="down_progress_fill"
                    style={styles.down_progress_fill}
                  ></div>
                  <p style={styles.down_range_value_text}>{downValue}%</p>
                  <RangeSlider
                    value={downValue}
                    onChange={(changeEvent) =>
                      DownBalance(changeEvent.target.value)
                    }
                  />
                </div>
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${downValue === 10 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => DownBalance(10)}
                  >
                    10%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${downValue === 25 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => DownBalance(25)}
                  >
                    25%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${downValue === 50 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => DownBalance(50)}
                  >
                    50%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${downValue === 75 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => DownBalance(75)}
                  >
                    75%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${downValue === 100 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => DownBalance(100)}
                  >
                    Max
                  </div>
                </div>

                {walletaddress && !isEmpty(walletaddress) ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => HandleDownConfirm()}
                    disabled={isDown}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >  
                    Connect wallet
                  </button>
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${cascading.predictionFlipCardPage}`}>
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => {
                      setDown({ Downamount: "" });
                      setUp({ Upamount: "" });
                      setFlipCard(false);
                      setDownValue(0)
                      setupValue(0)
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                    }}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                    }}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>
                <input
                  type="text"
                  className={cascading.inputField}
                  name="Upamount"
                  id="Upamount"
                  value={up?.Upamount || ''}
                  onChange={(e) => Uphandlechange(e)}
                />
              </div>
              <div className={`${cascading.bottomSection}`}>
                {/* <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0,percentage]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div> */}
                <div className="custom_range_slider position-relative mt-3">
                  {/* <div
                    className="up_progress_fill"
                    style={{ width: `${upValue - 0.5}%` }}
                  ></div> */}
                  <div style={styles.up_progress_fill}></div>
                  <p style={styles.up_range_value_text}>{upValue}%</p>
                  <RangeSlider
                    value={upValue}
                    onChange={(changeEvent) =>
                      UpBalance(changeEvent.target.value)
                    }
                  />
                </div>
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${upValue === 10 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => UpBalance(10)}
                  >
                    10%
                  </div>

                  <divs
                    className={`${cascading.percentValue}  ${upValue === 25 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => UpBalance(25)}
                  >
                    25%
                  </divs>
                  <div
                    className={`${cascading.percentValue} ${upValue === 50 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => UpBalance(50)}
                  >
                    50%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${upValue === 75 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => UpBalance(75)}
                  >
                    75%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${upValue === 100 ? cascading.activePercentValue : ""
                      }`}
                    onClick={() => UpBalance(100)}
                  >
                    Max
                  </div>
                </div>

                {walletaddress && !isEmpty(walletaddress) ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    disabled={isUP}
                    onClick={() => HandleConfirm()}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect wallet
                  </button> 
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}{" "}
    </>
  );
};

export default PredictionFlipCard;
